import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
// @material-ui/core components
import { CircularProgress, withStyles, TextField } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import PasswordApi from "api/PasswordApi";

// import { FormattedMessage } from "react-intl";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

import { store } from "react-notifications-component";

class ResetPasswordSection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      enEnregistrement: false,
      tried: false,
      success: false,
      password: {
        pwd: "",
        conf: ""
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  methodeApi(pwd) {
    return PasswordApi.reset(this.props.id, pwd.pwd)
      .then(response => {
        console.log(response);

        if (response.Success === true) {
          store.addNotification({
            title: "Senha alterada!",
            message: "Agora você pode acessar o app e treinar a vontade.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          this.setState({ success: true });
        } else {
          store.addNotification({
            title: "Erro!",
            message: "Erro ao alterar a senha.",
            type: "danger",
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
      })
      .catch(erreur => {
        store.addNotification({
          title: "Erro!",
          message: "Erro ao alterar a senha.",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        throw erreur;
      });
  }

  handleSubmit(event) {
    this.setState({ tried: true });

    const { password } = this.state;
    event.preventDefault();

    if (this.isPasswordValid(false) && this.isConfirmPasswordValid(false)) {
      this.setState({ enEnregistrement: true }, () => {
        this.methodeApi(password)
          .catch(erreur => {
            console.error(erreur);
          })
          .finally(() => {
            this.setState({ enEnregistrement: false });
          });
      });
    }
  }

  handleNameChange(newValue) {
    const { password } = this.state;
    this.setState({ password: { ...password, pwd: newValue } });
  }

  handleEmailChange(newValue) {
    const { password } = this.state;
    this.setState({ password: { ...password, conf: newValue } });
  }

  isPasswordValid(checkTried = true) {
    const { password, tried } = this.state;
    if (checkTried && !tried) {
      return true;
    }

    return password.pwd && password.pwd.length >= 6;
  }

  isConfirmPasswordValid(checkTried = true) {
    const { password, tried } = this.state;
    if (checkTried && !tried) {
      return true;
    }

    return (
      password.conf &&
      password.conf.length >= 6 &&
      password.conf === password.pwd
    );
  }

  render() {
    const { classes } = this.props;
    const { password, enEnregistrement, success } = this.state;

    if (success) {
      return (
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>
                <FormattedMessage
                  id="app.reset-password-title"
                  defaultMessage="Senha alterada!"
                  description="senha alterada title"
                />
              </h1>
              <h3 className={classes.description}>
                <FormattedMessage
                  id="app.reset-password-title-texte"
                  defaultMessage="Agora com sua nova senha e seu login ativo, acabaram as desculpas para ficar sem treinar. Você pode acessar o aplicativo de onde e quando quiser. Aproveite!"
                  description="senha alterada description"
                />
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem cs={3} sm={3} md={3}>
              <h3 className={classes.description}>Reset Password!</h3>
              <form onSubmit={this.handleSubmit}>
                <Grid container justify="center" spacing={3}>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      error={!this.isPasswordValid()}
                      fullWidth
                      helperText={
                        this.isPasswordValid() ? "" : "Senha invalida"
                      }
                      id="password"
                      inputProps={{
                        className: classes.disableOutline,
                        maxLength: 60
                      }}
                      label="Password"
                      type="password"
                      onChange={event =>
                        this.handleNameChange(event.target.value)
                      }
                      required
                      value={password.pwd}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      error={!this.isConfirmPasswordValid()}
                      fullWidth
                      helperText={
                        this.isConfirmPasswordValid()
                          ? ""
                          : "Confirmacao invalida"
                      }
                      id="confpassword"
                      inputProps={{
                        className: classes.disableOutline,
                        maxLength: 60
                      }}
                      label="Confirm Password"
                      type="password"
                      onChange={event =>
                        this.handleEmailChange(event.target.value)
                      }
                      required
                      value={password.conf}
                    />
                  </GridItem>

                  {!enEnregistrement ? (
                    <Button color="primary" type="submit">
                      Gravar
                    </Button>
                  ) : (
                    <CircularProgress color="secondary" size={30} />
                  )}
                </Grid>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }
}

ResetPasswordSection.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};

export default withStyles(styles)(ResetPasswordSection);
