import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import LanguageContexte from "contexte/LanguageContexte";
import { IntlProvider } from "react-intl";

import messages_en from "translations/en.json";
import messages_fr from "translations/fr.json";
import messages_pt from "translations/pt.json";

// import axios from "axios";

// Sections for this page
import ProductSection from "./Sections/ProductSection";
import TeamSection from "./Sections/TeamSection";
import WorkSection from "./Sections/WorkSection";
import { FormattedMessage } from "react-intl";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function HomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const messages = {
    en: messages_en,
    fr: messages_fr,
    pt: messages_pt
  };
  const { language } = useContext(LanguageContexte);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Carol Macedo Personal"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/home-image.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>
                <FormattedMessage
                  id="app.have-you-trained"
                  defaultMessage="Did you workout today?"
                  description="Banner text"
                />
              </h1>
              <h4>
                <FormattedMessage
                  id="app.banner-text"
                  defaultMessage="Já imaginou como ia melhorar sua rotina se tudo que precisasse saber estivesse dentro do seu bolso? Com o app CMP ficou facíl, tenha acesso a treinos, sua evolução e fique por dentro dos detalhes sobre sua alimentação."
                  description="Banner text"
                />
              </h4>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <WorkSection />
        </div>
      </div>
      <Footer />
    </IntlProvider>
  );
}
