import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { CircularProgress, withStyles, TextField } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

import EmailApi from "api/EmailApi";

import { FormattedMessage } from "react-intl";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

import { store } from "react-notifications-component";

class WorkSection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      enEnregistrement: false,
      tried: false,
      email: {
        name: "",
        emailAddress: "",
        message: ""
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  methodeApi(email) {
    return EmailApi.sendEmail(email)
      .then(() => {
        store.addNotification({
          title: "Email enviado!",
          message:
            "Obrigado pela mensagem, aguarde que em breve entraremos em contato.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      })
      .catch(erreur => {
        store.addNotification({
          title: "Mensagem não enviada!",
          message: "Erro ao enviar a mensagem.",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        throw erreur;
      });
  }

  handleSubmit(event) {
    // console.log("submit");
    this.setState({ tried: true });

    const { email } = this.state;
    event.preventDefault();

    if (
      this.isNameValid(false) &&
      this.isEmailValid(false) &&
      this.isMessageValid(false)
    ) {
      this.setState({ enEnregistrement: true }, () => {
        this.methodeApi(email)
          .catch(erreur => {
            console.error(erreur);
          })
          .finally(() => {
            this.setState({ enEnregistrement: false });
          });
      });
    }
  }

  handleNameChange(newValue) {
    const { email } = this.state;
    this.setState({ email: { ...email, name: newValue } });
  }

  handleEmailChange(newValue) {
    const { email } = this.state;
    this.setState({ email: { ...email, emailAddress: newValue } });
  }

  handleMessageChange(newValue) {
    const { email } = this.state;
    this.setState({ email: { ...email, message: newValue } });
  }

  isNameValid(checkTried = true) {
    const { email, tried } = this.state;
    if (checkTried && !tried) {
      return true;
    }

    return email.name;
  }

  isEmailValid(checkTried = true) {
    const { email, tried } = this.state;
    if (checkTried && !tried) {
      return true;
    }

    return /.+@.+\.[A-Za-z]+$/.test(email.emailAddress);
  }

  isMessageValid(checkTried = true) {
    const { email, tried } = this.state;
    if (checkTried && !tried) {
      return true;
    }

    return email.message;
  }

  render() {
    const { classes } = this.props;
    const { email, enEnregistrement } = this.state;

    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>
              <FormattedMessage
                id="app.contact-us"
                defaultMessage="Contact us"
                description="Contact us"
              />
            </h2>
            <h4 className={classes.description}>
              Trabalhamos na inovação, melhoria e satisfação para as atividades
              físicas diárias. Desenvolvemos métodos e treinos eficazes, de
              acordo com a necessidade e limitações de cada um. E muito em breve
              traremos novidades.
            </h4>
            <form onSubmit={this.handleSubmit}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    error={!this.isNameValid()}
                    fullWidth
                    helperText={this.isNameValid() ? "" : "Name invalid"}
                    id="name"
                    inputProps={{
                      className: classes.disableOutline,
                      maxLength: 60
                    }}
                    label="Name"
                    onChange={event =>
                      this.handleNameChange(event.target.value)
                    }
                    required
                    value={email.name}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    error={!this.isEmailValid()}
                    fullWidth
                    helperText={this.isEmailValid() ? "" : "Email invalid"}
                    id="email"
                    inputProps={{
                      className: classes.disableOutline
                    }}
                    label="Email"
                    onChange={event =>
                      this.handleEmailChange(event.target.value)
                    }
                    required
                    type="email"
                    value={email.emailAddress}
                  />
                </GridItem>
                <CustomInput
                  labelText="Message"
                  id="message"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  inputProps={{
                    error: !this.isMessageValid(),
                    multiline: true,
                    required: true,
                    rows: 5,
                    value: email.message,
                    onChange: event =>
                      this.handleMessageChange(event.target.value)
                  }}
                />
                <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                  {!enEnregistrement ? (
                    <Button color="primary" type="submit">
                      <FormattedMessage
                        id="app.send-message"
                        defaultMessage="Send message"
                        description="Send message"
                      />
                    </Button>
                  ) : (
                    <CircularProgress color="secondary" size={30} />
                  )}
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

WorkSection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WorkSection);
