import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import EmailApi from "api/EmailApi";

// Sections for this page
import HomePage from "./HomePage";
import Error404 from "views/HomePage/Error404";
import WelcomePage from "./WelcomePage";

const ERREUR_404 = "404";

//export default function EmailValidationPage(props) {
class EmailValidationPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      enEnregistrement: false,
      erreur: null,
      welcome: null
    };
  }

  componentDidMount() {
    this.methodeApi(this.props.id);
  }

  methodeApi(guid) {
    return EmailApi.activingEmail(guid)
      .then(response => {
        if (!response.Success) {
          this.setState({ erreur: new Error("404") });
        } else {
          // console.log("welcome page");
          this.setState({ welcome: true });
        }
      })
      .catch(erreur => {
        this.setState({ erreur: erreur });
        // throw erreur;
      });
  }

  render() {
    const { erreur, welcome } = this.state;

    if (erreur && erreur.message === ERREUR_404) {
      return <Error404 />;
    }
    if (welcome && welcome === true) {
      return <WelcomePage />;
    }

    return <HomePage />;
  }
}

EmailValidationPage.propTypes = {
  id: PropTypes.string
};

export default EmailValidationPage;
