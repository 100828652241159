import React from "react";
// react component for multi language
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <FormattedMessage
              id="app.our-programs"
              defaultMessage="Our programs"
              description="Our programs title"
            />
          </h2>
          <h5 className={classes.description}>
            <FormattedMessage
              id="app.program-description"
              defaultMessage="Os Programas 2ouMaisFisioSports foram criados para atender às suas necessidades específicas. Ao escolher um deles, você terá um treinamento adequado ao seu perfil e a garantia de maior satisfação com a atividade física. Contando também com reabilitações e prevenções de lesões, para a máxima performance, saúde e qualidade de vida."
              description="program description"
            />
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.functional"
              title="Treinamento Functional"
              description="O treinamento funcional tem se tornado cada vez mais popular por reduzir medidas, aumentar a massa muscular e modelagem corporal. Baseado em movimentos naturais do ser humano, como por exemplo pular, correr e agachar. O praticante ganha força, equilibrio, flexibilidade, condicionamento físico, resistência e agilidade."
              icon={VerifiedUser}
              imagem={require("assets/img/programs/funcional.svg")}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.bodybuilding"
              title="Bodybuilding"
              description="A musculação é uma modalidade de treinamento físico realizado contra resistência de pesos externos. Tendo como principal objetivo o aumento de massa muscular, ganho de força e definição muscular. Alguns dos benefícios são: melhora da postura, acelerar o metabolismo e ajudar na diminuição de gordura."
              icon={VerifiedUser}
              imagem={require("assets/img/programs/dumbell.svg")}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.running"
              title="Running"
              description="Criamos planilhas personalizadas para o seu objetivo, seja correr só por prazer ou correr longas distâncias. Nem precisamos dizer que os benefícios da corrida são inúmeros, tais como: emagrecimento, melhora do condicionamento físico, qualidade do sono, entre outros..."
              icon={Fingerprint}
              imagem={require("assets/img/programs/running.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.muay-thai"
              title="Muay Thai"
              description="Treinos com técnicas da arte marcial, focados principalmente no emagrecimento, condicionamento físico e coordenação motora."
              icon={Chat}
              imagem={require("assets/img/programs/boxing.svg")}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.stretching"
              title="Stretching"
              description="Além de melhorar a flexibilidade, melhorar a postura e proporcionar o relaxamento muscular, é possível melhorar a circulação sanguínea, entre outros benefícions..."
              icon={Fingerprint}
              imagem={require("assets/img/programs/yoga.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.physiotherapy"
              title="Physiotherapy"
              description="Oferecer qualidade de vida às pessoas é o principal objetivo do fisioterapeuta. Atuamos tanto na prevenção de lesões como na reabilitação física e funcional dos pacientes.
              * Necessário uma pré avaliação para determinar o tratamento."
              icon={Fingerprint}
              imagem={require("assets/img/programs/massage.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.acupuncture"
              title="Acupuncture"
              description="Através da medicina chinesa combinada com tecnicas da ciencia moderna, desenvolvemos técnicas para reabilitção, recuperação ou até mesmo na prevenção de lesões, trazendo mais conforto e alívio aos incomodos diários.
              * Necessário uma pré avaliação para determinar o tratamento."
              icon={Fingerprint}
              imagem={require("assets/img/programs/massage.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              appTitle="app.swimming"
              title="Swimming"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              imagem={require("assets/img/programs/swimming.svg")}
              iconColor="danger"
              vertical
            />
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  );
}
