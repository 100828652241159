/*eslint-disable*/
import React, { useContext } from "react";
import LanguageContexte from "contexte/LanguageContexte";
// import DeleteIcon from "@material-ui/icons/Delete";
// import IconButton from "@material-ui/core/IconButton";

// react component for multi language
import { FormattedMessage } from "react-intl";

// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { Icon , Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Language, PhoneIphone, Done } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
  const { language, modifierContexte } = useContext(LanguageContexte);
  // console.log(language);

  const languages = {
    en: "English",
    fr: "Français",
    pt: "Português",
  };
  const classes = useStyles();

  function onChangeLanguage(language) {
    modifierContexte({
      language: language,
    });
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/App"
          color="transparent"
          className={classes.navLink}
        >
          <PhoneIphone className={classes.icons} /> 
          <FormattedMessage
            id="app.our-app"
            defaultMessage="Iae já treinou hoje?"
            description="Banner text"
          />
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/appcmp/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/appcmp"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="whatsapp-tooltip"
          title="Send us a message"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://api.whatsapp.com/send?phone=15819965773&text=Olá, gostaria de mais informações sobre planos e treinos."
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-whatsapp"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={languages[language]}
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Language}
          dropdownList={[
            <Button onClick={() => onChangeLanguage("en")}
              color="transparent"
              className={classes.navLink}
            >
              {language === "en" ? <Done className={classes.icons} /> : null}
              English
            </Button>,
            <Button onClick={() => onChangeLanguage("fr")}
              color="transparent"
              className={classes.navLink}
            >
              {language === "fr" ? <Done className={classes.icons} /> : null}
              Français
            </Button>,
            <Button onClick={() => onChangeLanguage("pt")}
            color="transparent"
            className={classes.navLink}
            >
              {language === "pt" ? <Done className={classes.icons} /> : null}
              Português
            </Button>
          ]}
        />
      </ListItem>
    </List>
  );
}
