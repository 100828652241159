import React, { Fragment } from "react";

// import { createBrowserHistory } from "history";
// import { Redirect, Router, Route, Switch } from "react-router-dom";

import { Router } from "@reach/router";

import LanguageProvider from "contexte/LanguageProvider";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

// pages for this product
// import Components from "views/Components/Components.js";
// import LandingPage from "views/LandingPage/LandingPage.js";
// import ProfilePage from "views/ProfilePage/ProfilePage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";
import Error404 from "views/HomePage/Error404";
import HomePage from "views/HomePage/HomePage";
import AppPage from "views/HomePage/AppPage";
import EmailValidationPage from "views/HomePage/EmailValidationPage";
import ResetPasswordRoute from "views/HomePage/ResetPasswordRoute";
import WelcomePage from "views/HomePage/WelcomePage";

function App() {
  // console.log(language);
  // var hist = createBrowserHistory();

  return (
    <LanguageProvider>
      {/* <IntlProvider locale={language} messages={messages[language]}></IntlProvider> */}
      <ReactNotification />
      {/* <Router history={hist}> */}
      <Router component={Fragment} primary={false}>
        <Error404 default />
        <HomePage path="/" />
        <AppPage path="/App" />
        <WelcomePage path="/Welcome" />
        <EmailValidationPage path="/EmailValidation/:id" />
        <ResetPasswordRoute path="/ResetPassword/:id" />
        {/*<Switch>
           <Route path="/landing-page" component={LandingPage} />
          <Route path="/profile-page" component={ProfilePage} />
          <Route path="/login-page" component={LoginPage} />
          <Route path="/Componets" component={Components} /> 
          <Route path="/" component={HomePage} />
          <Route path="/App" component={AppPage} />
          <Redirect from="*" to="/" />
        </Switch>*/}
      </Router>
    </LanguageProvider>
  );
}

export default App;
