import fetch from "utils/fetch";

//const lienController = "http://localhost:47472/Home/SendEmail";
//const lienEmailContact = "http://localhost:47472/Home/EmailContact";
//const lienActivingEmail = "http://localhost:47472/Home/ActivingEmail";

const lienEmailContact = "http://API.carolmacedopersonal.com/Home/EmailContact";
const lienActivingEmail =
  "http://API.carolmacedopersonal.com/Home/ActivingEmail";

function convertirEmailIntoJson(email) {
  return JSON.stringify({
    ID: 20,
    Name: email.name,
    EmailAddress: email.emailAddress,
    Message: email.message
  });
}

export default class EmailApi {
  static sendEmail(email) {
    return fetch(lienEmailContact, {
      body: convertirEmailIntoJson(email),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
  }

  static activingEmail(guid) {
    return fetch(lienActivingEmail, {
      body: JSON.stringify({ id: guid }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
  }
}
