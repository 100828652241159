const NO_CONTENT = 204;
const NO_FOUND = 404;
const CONFLIT = 409;

const erreursParticulieres = new Set([NO_FOUND, CONFLIT]);

export default (input, init = {}) =>
  fetch(input, { credentials: "same-origin", ...init }).then(
    reponse =>
      new Promise((resolve, reject) => {
        if (erreursParticulieres.has(reponse.status)) {
          return reject(new Error(reponse.status));
        }

        if (!reponse.ok) {
          return reponse.json().then(reject);
        }

        if (reponse.status === NO_CONTENT) {
          return resolve();
        }

        return reponse.json().then(resolve);
      })
  );
