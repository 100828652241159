import fetch from "utils/fetch";

const lienResetPasswordTokenValidation =
  "http://API.carolmacedopersonal.com/Home/ResetPasswordTokenValidation";

const lienResetPassword =
  "http://API.carolmacedopersonal.com/Home/ResetingPassword";

export default class PasswordApi {
  static tokenValidation(guid) {
    return fetch(lienResetPasswordTokenValidation, {
      body: JSON.stringify({ id: guid }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
  }

  static reset(guid, password) {
    return fetch(lienResetPassword, {
      body: JSON.stringify({ id: guid, password: password }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    });
  }
}
