import React from "react";
// react component for multi language
import { FormattedMessage } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import ImagePanel from "components/Parallax/ImagePanel.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <FormattedMessage
              id="app.our-app"
              defaultMessage="Our app"
              description="Our app title"
            />
          </h2>
          <ImagePanel image={require("assets/img/App.png")} />
          <h5 className={classes.description}>
            <FormattedMessage
              id="app.program-description"
              defaultMessage="Os Programas 2ouMaisFisioSports foram criados para atender às suas necessidades específicas. Ao escolher um deles, você terá um treinamento adequado ao seu perfil e a garantia de maior satisfação com a atividade física. Contando também com reabilitações e prevenções de lesões, para a máxima performance, saúde e qualidade de vida."
              description="program description"
            />
          </h5>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={2}>
          <a
            href="https://apps.apple.com/us/app/cmp-fit/id1503577634"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImagePanel
              image={require("assets/img/App_Store.png")}
              style={{
                height: "60px"
              }}
            />
          </a>
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <a
            href="https://play.google.com/store/apps/details?id=com.carolmacedopersonal.droid"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImagePanel
              image={require("assets/img/Google_Play.png")}
              style={{
                height: "60px"
              }}
            />
          </a>
        </GridItem>
      </GridContainer>
    </div>
  );
}
