/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import LanguageContexte from "./LanguageContexte";

class LanguageProvider extends Component {
  constructor(props) {
    super(props);

    this.modifierContexte = this.modifierContexte.bind(this);
    this.setState = this.setState.bind(this);

    this.state = {
      language: "pt",
      modifierContexte: this.modifierContexte
    };
  }

  modifierContexte(modifications) {
    this.setState(modifications);
  }

  render() {
    const { children } = this.props;
    return (
      <LanguageContexte.Provider value={this.state}>
        {children}
      </LanguageContexte.Provider>
    );
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LanguageProvider;
